<template>
  <v-card>
    <v-card-title>{{ $t("page.resetPassword.title") }}</v-card-title>
    <v-card-text>
      <v-form v-model="form">
        <v-text-field
          v-model="credentials.email"
          :label="$t('page.resetPassword.fields.email.title')"
          :rules="emailRules"
        ></v-text-field>
        <v-text-field
          v-model="credentials.password"
          :label="$t('page.resetPassword.fields.password.title')"
          :rules="passwordRules"
        ></v-text-field>
        <v-text-field
          v-model="confirmPassword"
          :label="$t('page.resetPassword.fields.passwordConfirm.title')"
          :rules="confirmPasswordRules"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        large
        class="primary px-12"
        :disabled="!form"
        @click="resetPassword()"
        >{{ $t("page.resetPassword.actions.reset") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import lengthRules from "/rules/length-rule";
export default {
  props: ["enc"],
  components: {},
  data() {
    return {
      form: null,
      confirmPassword: null,
      credentials: {},
      emailRules: [
        (v) => {
          var re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
          return re.test(v) || this.$t("page.resetPassword.fields.email.valid");
        },
      ],
      passwordRules: [
        lengthRules.notEmpty(
          this.$t("page.resetPassword.fields.passwordConfirm.cannotBeEmpty")
        ),
        lengthRules.min(
          8,
          this.$t("page.resetPassword.fields.password.minLength", {
            minLength: 8,
          })
        ),
        (v) => {
          var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
          return (
            re.test(v) ||
            this.$t("page.resetPassword.fields.password.specialCharacters")
          );
        },
      ],
      confirmPasswordRules: [
        (v) =>
          !!v ||
          this.$t("page.resetPassword.fields.passwordConfirm.cannotBeEmpty"),
        (v) =>
          v == this.credentials.password ||
          this.$t("page.resetPassword.fields.passwordConfirm.doesNotMatch"),
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    resetPassword() {
      var reqObj = {
        email: this.credentials.email,
        newPassword: this.credentials.password,
        token: this.enc,
      };
      //console.log(reqObj);
      this.$store.dispatch("auth/resetPassword", reqObj);
    },
  },
};
</script>